import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Typography,
  Tooltip,
  Switch,
  Modal,
  Alert,
  Popconfirm,
  Skeleton,
  Select,
  DatePicker,
  Descriptions
} from "antd";
import { useEffect, useState } from "react";
import { SaveOutlined, MailOutlined } from "@ant-design/icons";
import { setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { connect } from "react-redux";
import moment from "moment";
import localization from "moment/locale/fr";
import {
  toGetFactures,
  toPrintFacture,
  deleteFacture,
  payFacture,
  toUnlockFacture,
  toUpdateFactureMf,
  toPrintRetenu,
  toGetClientsListIds,
  toGetTotalFactureVente,
  toGetFacturesAll,
  toPrintFactureNew
} from "../APIs";
import printJS from "print-js";
import FactureModal from "./FactureModal";
import {
  customFilter,
  successMsg,
  toConvertDate,
  verifyAdminCode
} from "../../../Utils/Utils";
import ReglementFacture from "../Reglement/ReglementFacture";
import Search from "../../../Utils/Search";
import cookie from "react-cookies";
import DataTable, { defaultThemes } from "react-data-table-component";
import ls from "localstorage-ttl";
import Loading from "react-loading-bar";
import "react-loading-bar/dist/index.css";
import Retenu from "./Retenu";
import { toGetUsers } from "../../Compte/Api";
import Email from "../Email";
import ModalCode from "../ModalCode";

moment.updateLocale("fr", localization);

const fields = [
  { name: "Référence", field: "s__ref", type: "varchar" },
  { name: "Total", field: "d__totalFacture", type: "number" },
  { name: "Client", field: "clientName", type: "varchar" },
  {
    name: "Client Affiché",
    field: "s__displayableClientName",
    type: "varchar"
  },
  { name: "Matricule Fiscale", field: "mf", type: "varchar" },
  { name: "Remise", field: "discount", type: "number" },
  { name: "Payé", field: "b__paied", type: "boolean" },
  { name: "Date de création", field: "dt__createdDate", type: "date" },
  { name: "Nombre de bon de livraisons", field: "nb", type: "number" },
  { name: "Région", field: "region", type: "varchar" }
];
const { Option } = Select;

const FacturesList = ({ AppStore, ...props }) => {
  const [emailModal, setEmailModal] = useState(false);
  const [selectedMethode, setSelectedMethode] = useState(2);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("0");
  const [form] = Form.useForm();

  const [factures, setFactures] = useState([]);
  const [entete, setEntete] = useState(true);
  const [facturesAll, setFacturesAll] = useState([]);
  const [factureToInspect, setFactureToInspect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedFactures, setSelectedFactures] = useState([]);
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);
  const [isFactureModalVisible, setIsFactureModalVisible] = useState(false);
  const [wrongAdminCode, setWrongAdminCode] = useState(false);
  const [adminCode, setAdminCode] = useState("");
  const [show, setShow] = useState(true);
  const [displayCRI, setDisplayCRI] = useState(false);

  const [displayDate, setDisplayDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    cookie.load("role") == "Super Admin" ? "0" : "2"
  );
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(
    cookie.load("role") == "Super Admin" ? "0" : cookie.load("id")
  );
  const [selectedDateValue, setSelectedDateValue] = useState("0");

  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const [total, setTotal] = useState(0);
  const [totalUnpaied, setTotalUnpaied] = useState(0);
  const [totalPaied, setTotalPaied] = useState(0);

  useEffect(() => {
    AppStore.setFirst(1);
    AppStore.setSecond(9);

    fetchFactures(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
    toGetClientsListIds().then((_clients) => {
      setClients(_clients);
    });

    toGetUsers().then((users) => {
      setUsers(users);
    });
  }, [cookie.load("selectedMenu")]);

  const toUpdateData = (values) => {
    setFactures(values);
    setFilterModalVisible(false);
  };

  const toGetData = () => {
    if (null != ls.get("factures")) {
      setFactures(ls.get("factures"));
      setFacturesAll(ls.get("factures"));
      setLoading(false);
    }
    actualise();
  };

  const actualise = (nb) => {
    setShow(true);
    fetchFactures(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onChange = () => {
    setEntete(!entete);
  };

  const onSearch = (e) => {
    // setSearchValue(e.target.value);
    // const updatedList = customFilter(e.target.value, facturesAll, [
    //   "clientName",
    //   "d__total",
    //   "s__ref",
    //   "s__displayableClientName",
    //   "s__displayableMF"
    // ]);
    // setFactures(updatedList);
    setSearchValue(e);

    setLoading(true);

    fetchFactures(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: e
    });
  };
  const printFactureNew = (e, grouped) => {
    e.preventDefault();
    
    toPrintFactureNew(selectedFactures[0].id, grouped).then(() => {
      let newFactures = [...factures];
      let index = factures.findIndex((c) => c.id == selectedFactures[0].id);

      newFactures[index].b__locked = 1;
      setFacturesAll(newFactures);
    });
  };

  const printFacture = (e, detail) => {
    e.preventDefault();
    var titre1 = true;
    if (e.type == "contextmenu") {
      titre1 = false;
    }

    if (selectedFactures.length == 1) {
      toPrintFacture(selectedFactures[0].id, titre1, entete, detail).then(
        () => {
          let newFactures = [...factures];
          let index = factures.findIndex((c) => c.id == selectedFactures[0].id);

          newFactures[index].b__locked = 1;
          setFacturesAll(newFactures);
        }
      );
    } else {
      printJS({
        printable: getJsonToPrint(),
        style: "body { margin: 0; font-size: 12px; }",
        properties: [
          "chargé",
          "date",
          "ref",
          "client",
          "total",
          "reste",
          "etat"
        ],
        type: "json",
        header: "<h4> Liste des factures </h4>"
      });
    }
  };

  const printAllFactures = () => {
    setLoading(true);
    setShow(true);
    toGetFacturesAll(0, 10, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    }).then((data) => {
      var res = [];
      data.res.forEach((element) => {
        res.push({
          chargé: element.createdBy,
          date: toConvertDate(element.dt__createdDate),
          reste: element.d__reste.toFixed(3),
          ref: element.s__ref,
          client: element.clientName,
          total: element.d__totalFacture.toLocaleString(undefined, {
            minimumFractionDigits: 3
          }),
          etat: element.b__paied ? "Payé" : "non payé"
        });
      });
      printJS({
        printable: res,
        style: "body { margin: 0; font-size: 12px; }",
        properties: [
          "ref",
          "date",
          "chargé",
          "client",
          "total",
          "reste",
          "etat"
        ],
        type: "json",
        header: "<h4> Liste des factures </h4>"
      });
      setLoading(false);
      setShow(false);
    });
  };

  const printRetenu = () => {
    toPrintRetenu(selectedFactures[0].id);
  };

  const toDeleteFacture = async () => {
    if (selectedFactures.length == 1) {
      var facture = selectedFactures[0];

      // if (isAdminModalVisible) {
      //   try {
      //     await verifyAdminCode(adminCode);
      //   } catch (e) {
      //     setWrongAdminCode(true);
      //     return;
      //   }
      // }

      if (facture.b__locked && !isAdminModalVisible) {
        setAdminModalVisible(true);
      } else {
        deleteFacture(facture.id).then((res) => {
          let newFactures = factures.filter((c) => c.id !== facture.id);
          setFactures(newFactures);
          setFacturesAll(newFactures);
          setSelectedFactures([]);
          setAdminCode("");
          setWrongAdminCode(false);
          setAdminModalVisible(false);
        });
      }
    }
  };

  const unlockFacture = () => {
    toUnlockFacture(selectedFactures[0].id).then(() => {
      let newFactures = [...factures];
      let index = factures.findIndex((c) => c.id == selectedFactures[0].id);
      newFactures[index].b__locked = 0;
      setFacturesAll(newFactures);
    });
  };

  const toDisplayDollar = () => {
    var display = true;
    if (selectedFactures.length > 1) return false;
    selectedFactures.forEach((element) => {
      element.b__paied && (display = false);
    });

    return display;
  };

  const toPayeFacture = (reglements, reste) => {
    setIsFactureModalVisible(false);
    payFacture(selectedFactures[0].id, reglements, reste).then(() => {
      setSelectedFactures([]);
      setLoading(true);
      toGetData();
    });
  };

  const toUpdateClientAndMF = (quote) => {
    var data = {
      clientName: quote.s__displayableClientName,
      mf: quote.s__displayableMF,
      id: quote.id
    };
    toUpdateFactureMf(data).then(() => {
      successMsg("informations mis à jours avec succée! ");
    });
  };

  const toUpdateMf = (f, value) => {
    const newFactures = [...factures];
    const index = newFactures.findIndex((a) => a.id === f.id);

    newFactures[index].s__displayableMF = value;
    setFactures(newFactures);
  };

  const toUpdateClientName = (f, value) => {
    const newFactures = [...factures];
    const index = newFactures.findIndex((a) => a.id === f.id);

    newFactures[index].s__displayableClientName = value;
    setFactures(newFactures);
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "1rem"
      }
    }
  };

  const getJsonToPrint = () => {
    var res = [];
    selectedFactures.forEach((element) => {
      res.push({
        chargé: element.createdBy,
        date: toConvertDate(element.dt__createdDate),
        ref: element.s__ref,
        client: element.clientName,
        total: element.d__totalFacture,
        etat: element.b__paied ? "Payé" : "non payé",
        reste: element.d__reste.toFixed(3)
      });
    });
    return res;
  };

  const getHeader = () => {
    var result = "";
    if (selectedMethode != "Tout") {
      result += "-Etat: " + selectedMethode;
    }
    if (selectedDate != "Tout") {
      result += " -Date: " + selectedDate;
    }
    if (selectedClient != "Tout") {
      result += " -Client: " + selectedClient;
    }
    if (selectedUser != "Tout") {
      result += " -Utilisateur: " + selectedUser;
    }

    return result;
  };

  const columns = [
    {
      name: "Client",
      selector: "clientName",
      sortable: true
    },

    {
      name: "Crée par",
      selector: "createdBy",
      sortable: true,
      grow: 0.75
    },
    {
      name: "Date",
      selector: "dt__createdDate",
      sortable: true,
      format: (row) => `${toConvertDate(row.dt__createdDate)}`
    },
    {
      name: "Référence",
      selector: "s__ref",
      sortable: true,
      format: (f) => (
        <Typography.Link
          level={5}
          style={{ cursor: "pointer" }}
          onClick={() => setFactureToInspect(f)}
        >
          {f.s__ref}
        </Typography.Link>
      )
    },
    {
      name: "Client affiché",
      selector: "s__displayableClientName",
      sortable: true,
      grow: 1.5,

      format: (bl) => (
        <Row gutter={24}>
          <Col span={24}>
            <Input
              onBlur={(e) => toUpdateClientName(bl, e.target.value)}
              defaultValue={bl.s__displayableClientName}
            />
          </Col>
        </Row>
      )
    },
    {
      name: "MF affiché",
      selector: "s__displayableMF",
      sortable: true,
      grow: 1.5,
      format: (bl) => (
        <Row gutter={24}>
          <Col span={18}>
            <Input
              onBlur={(e) => toUpdateMf(bl, e.target.value)}
              defaultValue={bl.s__displayableMF}
            />
          </Col>
          <Col span={4} style={{ float: "left" }}>
            <Button
              type="link"
              onClick={() => toUpdateClientAndMF(bl)}
              icon={<SaveOutlined />}
            ></Button>
          </Col>
        </Row>
      )
    },
    {
      name: "Total",
      selector: "d__totalFacture",
      sortable: true,
      format: (row) => `${row.d__totalFacture.toFixed(3) + " DT"}`
    },
    {
      name: "Reste",
      selector: "d__reste",
      sortable: true,
      format: (row) => `${row.d__reste.toFixed(3) + " DT"}`
    },
    {
      name: "Payé",
      selector: "b__paied",
      sortable: true,
      format: (row) =>
        row.b__paied ? (
          <span className={"auto--color-success"}>Payé</span>
        ) : (
          <span className={"auto--color-danger"}>Non payé</span>
        )
    }
  ];

  const onSelectRow = (e) => {
    setSelectedFactures(e.selectedRows);
  };

  const onChangeCredit = (option) => {
    setSelectedMethode(option);
    fetchFactures(0, {
      paied: option,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onChangeClient = (option) => {
    const id =
      option == "0" ? "0" : clients.filter((el) => el.name == option)[0].id;
    setSelectedClient(id);
    fetchFactures(0, {
      paied: selectedMethode,
      client: id,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onChangeUsers = (option) => {
    var id = "0";
    if (option != "0") {
      id = users.filter((el) => el.username == option)[0].id;
    }
    setSelectedUser(id);
    fetchFactures(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: id,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const onChangeDate = (option) => {
    if (option == "5") {
      setSelectedDate(option);
      setDisplayDate(true);
      setDisplayDateRange(false);
      return;
    }
    if (option == "6") {
      setSelectedDate(option);
      setDisplayDateRange(true);
      setDisplayDate(false);
      return;
    }
    setDisplayDate(false);

    setSelectedDate(option);

    fetchFactures(0, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: option,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const fetchFactures = async (page, options) => {
    setLoading(true);
    setShow(true);
    setTotalRows(0);

    toGetFactures(page, perPage, options).then((data) => {
      setFactures(data.res);
      setFacturesAll(data.res);
      setLoading(false);
      setTotalRows(data.total);
      setShow(false);
    });
    toGetTotalFactureVente(options).then((data) => {
      setTotalRows(data.nb);
      setTotalUnpaied(data.totalUnpaied);
      setTotalPaied(data.totalPaied);
      setTotal(data.total);
    });
  };

  const handlePageChange = (page) => {
    fetchFactures(page - 1, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);
    setShow(true);

    toGetFactures(page - 1, newPerPage, {
      paied: selectedMethode,
      client: selectedClient,
      user: selectedUser,
      date: selectedDate,
      dateValue: selectedDateValue,
      ref: searchValue
    }).then((data) => {
      setFacturesAll(data.res);
      setFactures(data.res);
      setLoading(false);
      setShow(false);
    });

    setPerPage(newPerPage);
    setLoading(false);
  };

  return (
    <div id="vente">
      <ModalCode
        isAdminModalVisible={isAdminModalVisible}
        setAdminModalVisible={() => {
          setAdminModalVisible(false);
        }}
        onOk={() => toDeleteFacture()}
        text={"La facture a été imprimée"}
      />
      <Loading show={show} color="red" showSpinner={true} />
      <Form layout="vertical">
        <Row className="auto--custom-header">
          <Col span="4">
            <Typography.Title level={5}>LISTE DES FACTURES</Typography.Title>
          </Col>
          <Col span="3" style={{ textAlign: "right" }} justify="end">
            <Form.Item label="Réf,note,client/mf affiché">
              <Input.Search onSearch={onSearch} enterButton />
            </Form.Item>
          </Col>
          <Col span="2">
            <Form.Item label="Crédit">
              <Select
                onSelect={onChangeCredit}
                style={{ width: "80%" }}
                defaultValue="2"
              >
                <Option value="2">Tout</Option>
                <Option value="0">Non payé</Option>
                <Option value="1">Payé</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            <Form.Item label="Date">
              <Select
                showSearch
                onSelect={onChangeDate}
                style={{ width: "100%" }}
                defaultValue={cookie.load("role") == "Super Admin" ? "0" : "2"}
              >
                <Option value="0">Tout</Option>

                <Option value="1">Hier</Option>
                <Option value="2">Aujourd'hui</Option>
                <Option value="3">Ce mois</Option>
                {/* <Option value="4">Cette année</Option>*/}
                <Option value="5">Date Spécifique</Option>
                <Option value="6">Période Spécifique</Option>
              </Select>
            </Form.Item>
            {displayDate && (
              <DatePicker
                style={{ width: "100%" }}
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchFactures(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue
                  });
                }}
              />
            )}
            {displayDateRange && (
              <DatePicker.RangePicker
                onChange={(e, date) => {
                  setSelectedDateValue(date);
                  fetchFactures(0, {
                    paied: selectedMethode,
                    client: selectedClient,
                    user: selectedUser,
                    date: selectedDate,
                    dateValue: date,
                    ref: searchValue
                  });
                }}
              />
            )}
          </Col>

          <Col span="4">
            <Form.Item label="Client">
              <Select
                showSearch
                onSelect={onChangeClient}
                style={{ width: "80%" }}
                defaultValue="0"
              >
                <Option value="0">Tout</Option>

                {clients.map((c) => (
                  <Option value={c.name}>{c.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span="4">
            {users.length > 0 && (
              <Form.Item label="Utilisateurs">
                <Select
                  showSearch
                  onSelect={onChangeUsers}
                  style={{ width: "80%" }}
                  defaultValue={
                    cookie.load("role") == "Super Admin"
                      ? "0"
                      : users.length > 0 &&
                        users.filter((u) => u.id == cookie.load("id"))[0]
                          .username
                  }
                >
                  <Option value="0">Tout</Option>

                  {users.map((u) => (
                    <Option value={u.username}>{u.username}</Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Col>

          <Col span="3" style={{ textAlign: "right" }}>
            {/* <Button
              type="link"
              onClick={(e) => setFilterModalVisible(true)}
              icon={
                <img
                  src="/images/filterIconVente.svg"
                  style={{ width: "3vh", height: "3vh" }}
                />
              }
              style={{ marginRight: "5px" }}
            ></Button> */}

            <Tooltip title="Actualiser la liste des factures">
              <Button
                onClick={() => actualise()}
                type="link"
                size="large"
                style={{ marginRight: "15px" }}
                icon={<img src="/images/refresh.svg" />}
              ></Button>
            </Tooltip>
            <Tooltip
              title="Pour imprimer le résultat de la recherche"
              color="#58c5cc"
              placement="leftTop"
            >
              <Button
                type="link"
                onClick={(e) => printAllFactures()}
                icon={
                  <img
                    src="/images/print.svg"
                    className="auto--icon-list"
                  ></img>
                }
              ></Button>
            </Tooltip>
          </Col>
        </Row>
      </Form>
      <Row gutter="24" style={{ marginTop: "1vh" }}>
        <Col span="23">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={factures}
            pagination
            selectableRows
            noHeader
            striped
            selectableRowsHighlight
            fixedHeader
            onSelectedRowsChange={(e) => onSelectRow(e)}
            persistTableHead
            noDataComponent={<span>Pas de facture trouvés</span>}
            contextMessage={{
              singular: "élément",
              plural: "éléments",
              message: "séléctionné(s)"
            }}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
          />
          {cookie.load("role") == "Super Admin" && total > 0 && (
            <Descriptions
              bordered
              size="small"
              style={{ backgroundColor: "white", marginTop: "3px" }}
            >
              <Descriptions.Item label="Total TTC" className="item-label">
                {total.toFixed(3) + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total payé" className="item-label">
                {totalPaied.toFixed(3) + " DT"}
              </Descriptions.Item>
              <Descriptions.Item label="Total Reste" className="item-label">
                {totalUnpaied.toFixed(3) + " DT"}
              </Descriptions.Item>
            </Descriptions>
          )}
        </Col>
        <Skeleton active loading={loading} />

        <Col span="1" className="auto--background-grey">
          {selectedFactures.length > 0 && (
            <div style={{ marginTop: "5vh" }}>
              {
                //toDisplayPrint() && (
                true && (
                  <>
                    <Tooltip
                      title="Pour imprimer avec/sans entête"
                      color="#58c5cc"
                      placement="leftTop"
                    >
                      <Switch checked={entete} onChange={() => onChange()} />
                    </Tooltip>
                    <Tooltip
                      title="Pour imprimer une facture détaillé"
                      color="#58c5cc"
                      placement="leftTop"
                    >
                      <Button
                        type="link"
                        onClick={(e) => printFacture(e, true)}
                        onContextMenu={(e) => printFacture(e, true)}
                        style={{ marginTop: "2vh" }}
                        icon={
                          <img
                            src="/images/print.svg"
                            className="auto--icon-list"
                          ></img>
                        }
                      ></Button>
                    </Tooltip>
                    <Tooltip
                      title="Pour imprimer une facture groupée"
                      color="#58c5cc"
                      placement="leftTop"
                    >
                      <Button
                        type="link"
                        onClick={(e) => printFacture(e, false)}
                        onContextMenu={(e) => printFacture(e, false)}
                        style={{ marginTop: "2vh" }}
                        icon={
                          <img
                            src="/images/print.svg"
                            className="auto--icon-list"
                          ></img>
                        }
                      ></Button>
                    </Tooltip>

                    <Tooltip
                      title="Pour imprimer une facture avec le nouveau modèle"
                      color="#58c5cc"
                      placement="leftTop"
                    >
                      <Button
                        type="link"
                        onClick={(e) => printFactureNew(e,true)}
                        onContextMenu={(e) => printFactureNew(e, false)}

                        style={{ marginTop: "2vh" }}
                        icon={
                          <img
                            src="/images/print.svg"
                            className="auto--icon-list"
                          ></img>
                        }
                      ></Button>
                    </Tooltip>
                  </>
                )
              }
              {selectedFactures.length === 1 && (
                <>
                  <Tooltip
                    placement="leftTop"
                    title="Détails du facture"
                    color="#58c5cc"
                  >
                    <Button
                      type="link"
                      style={{ marginTop: "2vh" }}
                      onClick={() => setFactureToInspect(selectedFactures[0])}
                      icon={
                        <img
                          src="/images/info.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Tooltip>
                  <Tooltip
                    title="Envoyer par email"
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Button
                      type="link"
                      onClick={(e) => {
                        setEmailModal(true);
                      }}
                      size="large"
                      style={{ marginTop: "2vh" }}
                      icon={<MailOutlined />}
                    ></Button>
                  </Tooltip>
                </>
              )}

              {toDisplayDollar() && (
                <Tooltip
                  title="Pour supprimer le(s) facture(s)"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Popconfirm
                    title={`Voulez vous vraiment supprimer la facture?`}
                    onConfirm={() => toDeleteFacture()}
                    okText="Oui"
                    cancelText="Non"
                    placement="left"
                  >
                    <Button
                      type="link"
                      style={{ marginTop: "2vh" }}
                      icon={
                        <img
                          src="/images/trash.svg"
                          className="auto--icon-list"
                        ></img>
                      }
                    ></Button>
                  </Popconfirm>
                </Tooltip>
              )}

              {toDisplayDollar() && (
                <Tooltip
                  title="payer le(s) facture(s)"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={() => setIsFactureModalVisible(true)}
                    style={{ marginTop: "2vh" }}
                    icon={
                      <img
                        src="/images/coin.svg"
                        className="auto--icon-list"
                      ></img>
                    }
                  ></Button>
                </Tooltip>
              )}

              {cookie.load("role") == "Super Admin" &&
              selectedFactures.length === 1 &&
              selectedFactures[0].b__locked ? (
                <Tooltip
                  title="supprimer le duplicata"
                  color="#58c5cc"
                  placement="leftTop"
                >
                  <Button
                    type="link"
                    onClick={() => unlockFacture()}
                    style={{ marginTop: "2vh" }}
                    icon={
                      <img
                        src="/images/unlock.png"
                        className="auto--icon-list"
                      ></img>
                    }
                  ></Button>
                </Tooltip>
              ) : (
                ""
              )}
              {selectedFactures.length === 1 &&
                selectedFactures[0].d__totalFacture > 1000 && (
                  <Tooltip
                    title="Pour imprimer le retenu à la source"
                    color="#58c5cc"
                    placement="leftTop"
                  >
                    <Button
                      style={{ marginTop: "2vh" }}
                      onClick={() => printRetenu()}
                    >
                      C.R.I
                    </Button>
                  </Tooltip>
                )}
            </div>
          )}
        </Col>
      </Row>
      {/* <Modal
        title="Operation non autorisée"
        visible={isAdminModalVisible}
        onOk={toDeleteFacture}
        onCancel={() => setAdminModalVisible(false)}
      >
        <p>
          Entrez le code admin pour autoriser cette opération. La facture a été
          imprimée!
        </p>
        {wrongAdminCode && (
          <Alert
            message="Code admin incorrect"
            type="error"
            style={{ marginBottom: 10 }}
          />
        )}
        <Input
          placeholder="Code admin"
          type="password"
          value={adminCode}
          onChange={(e) => setAdminCode(e.target.value)}
        />
      </Modal>*/}
      <Retenu
        visible={displayCRI}
        onClose={() => setDisplayCRI(false)}
        facture={selectedFactures.length === 1 && selectedFactures[0]}
      ></Retenu>
      <FactureModal
        id={null != factureToInspect && factureToInspect.id}
        onClose={() => setFactureToInspect(null)}
      />
      <Modal
        className="modal-large"
        visible={isFactureModalVisible}
        onCancel={() => setIsFactureModalVisible(false)}
        footer={[]}
      >
        <ReglementFacture
          toPayeFacture={toPayeFacture}
          facture={selectedFactures[0]}
        />
      </Modal>
      <Modal
        className="modal-small"
        title={"Filtrer"}
        visible={filterModalVisible}
        footer={[]}
        onCancel={(e) => setFilterModalVisible(false)}
      >
        <Search
          toUpdateData={toUpdateData}
          fields={fields}
          list={facturesAll}
        />
      </Modal>

      <Email
        id={selectedFactures.length > 0 && selectedFactures[0].id}
        setEmailModal={() => setEmailModal(false)}
        visible={emailModal}
        type="facture"
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote))
  };
};

export default connect(null, mapDispatchToProps)(FacturesList);
