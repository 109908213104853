import { Descriptions, Col, Image, Row, Card, Modal, Form, Input, Button, Alert, Switch, Tabs, DatePicker } from "antd";

import { useState, useEffect } from "react";
import { toChangeDisplay, toGetCode, toGetProfile, toRegenerateCode, toUpdateCredentials, toUpdatePercentage, toUpdateCode } from "./Api";
import moment from "moment";
import { EditOutlined, ReloadOutlined } from "@ant-design/icons";
import { errorMsg, successMsg } from "../../Utils/Utils";
import cookie from "react-cookies";

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const Profile = ({ user }) => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(null);

  const [displayName, setDisplayName] = useState(true);
  const [displayTel, setDisplayTel] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalCode, setModalCode] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [code, setCode] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [form] = Form.useForm();
  const [formCode] = Form.useForm();

  useEffect(() => {
    if (null != user) {
      setData(user);
      setDisplayName(user.displayName);
      setDisplayTel(user.displayTel);
    } else {
      toGetProfile().then((res) => {
        setData(res);
        setDisplayName(res.displayName);
        setDisplayTel(res.displayTel);
        formCode.setFieldsValue({
          s__code: res.code,
        });
      });
      toGetCode().then((res) => {
        setCode(res.code);

        setPercentage(res.percentage);
      });
      setLoading(false);
    }
  }, []);

  const cancelCreateModal = () => {
    form.setFieldsValue({
      username: data.username,
      password1: "",
      password2: "",
    });
    setModalVisible(false);
  };

  const saveCode = () => {
    formCode.validateFields().then((values) => {
      toUpdateCode(formCode.getFieldValue("s__code")).then((res) => {
        if (res) {
          successMsg("Code modifiée avec succés!");
          setModalCode(false);
        } else {
          errorMsg("Merci de choisir un autre code");
        }
      });
    });
  };
  const closeCreateModal = () => {
    form
      .validateFields()
      .then((values) => {
        if (values.password1 != values.password2) {
          setLoginError("Les mots de passe ne sont pas identiques!");
        } else {
          toUpdateCredentials(values.username, values.password1);
          cancelCreateModal();
        }
      })
      .catch((errorInfo) => {
        errorMsg(errorInfo["errorFields"][0]["name"] + " " + errorInfo["errorFields"][0]["errors"]);
      });
  };

  const onChange = (a, b) => {
    setDisplayName(a);
    setDisplayTel(b);

    toChangeDisplay(a, b);
  };
  const onChangeMonth = (m) => {
    console.log(m);
  };
  return (
    <>
      <Card style={{ marginTop: "2vh" }}>
        <Tabs>
          <Tabs.TabPane tab="Informations">
            {null != data && (
              <Row span={24}>
                <Col span={16}>
                  <Descriptions bordered>
                    <Descriptions.Item span={8} label="Nom">
                      {data.nom}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label="Prénom">
                      {data.prenom}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label="Username">
                      {data.username}
                    </Descriptions.Item>

                    <Descriptions.Item span={8} label="Téléphone">
                      {data.phone}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label="CIN">
                      {data.cin}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label="Role">
                      {data.role}
                    </Descriptions.Item>
                    <Descriptions.Item span={8} label="Date de création">
                      {moment(data.createdDate).format("D MMMM YYYY")}
                    </Descriptions.Item>
                    {null != data.createdBy && (
                      <Descriptions.Item span={8} label="Crée par">
                        {data.createdBy}
                      </Descriptions.Item>
                    )}
                    {null == user && (
                      <>
                        <Descriptions.Item span={8} label="Afficher mon nom dans l'impression">
                          <Switch onChange={() => onChange(!displayName, displayTel)} checked={displayName} />
                        </Descriptions.Item>

                        <Descriptions.Item span={8} label="Afficher mon téléphone dans l'impression">
                          <Switch onChange={() => onChange(displayName, !displayTel)} checked={displayTel} />
                        </Descriptions.Item>
                      </>
                    )}
                  </Descriptions>
                </Col>
              </Row>
            )}
            <Row span={24}>
              <Col span={8}></Col>
              <Col span={16} style={{ marginTop: "3vh", textAlign: "center" }}>
                <Button type="primary" onClick={(e) => setModalVisible(true)} size="large" icon={<EditOutlined />}>
                  Modifier mon username et mot de passe
                </Button>
                {cookie.load("role") == "Super Admin" && (
                  <Button type="primary" onClick={(e) => setModalCode(true)} size="large" style={{ marginLeft: "10px" }} icon={<EditOutlined />}>
                    Modifier mon code
                  </Button>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Avances">
            <DatePicker onChange={onChangeMonth} picker="month" />
            <table></table>
          </Tabs.TabPane>
        </Tabs>
      </Card>

      <Modal
        className="smtv-modal"
        title="Modifier username et password"
        visible={modalVisible}
        maskClosable={false}
        onCancel={(e) => cancelCreateModal()}
        footer={[
          <Button key="back" onClick={(e) => cancelCreateModal()}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={(e) => closeCreateModal()}>
            Enregistrer user
          </Button>,
        ]}
        onOk={closeCreateModal}
      >
        <Form
          form={form}
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            padding: "50px 10px",
          }}
          {...layout}
          name="basic"
          initialValues={{
            username: null != data ? data.username : "",
            password1: "",
            password2: "",
          }}
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre nom d'utilisateur!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password1"
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Retapez votre password"
            name="password2"
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          {loginError && <Alert message={loginError} type="error" style={{ marginBottom: 20 }} />}
        </Form>
      </Modal>
      <Modal
        className="smtv-modal"
        title="Modifier code"
        visible={modalCode}
        maskClosable={false}
        onCancel={(e) => setModalCode(false)}
        footer={[
          <Button key="back" onClick={(e) => setModalCode(false)}>
            Annuler
          </Button>,
          <Button key="submit" type="primary" onClick={(e) => saveCode()}>
            Enregistrer
          </Button>,
        ]}
      >
        <Form
          form={formCode}
          style={{
            backgroundColor: "white",
            border: "1px solid #ddd",
            padding: "50px 10px",
          }}
          name="basic"
        >
          <Form.Item
            label="Code"
            name="s__code"
            rules={[
              {
                required: true,
                message: "Veuillez saisir votre code!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Profile;
