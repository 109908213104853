import { useState, useEffect } from "react";
import {
  Modal,
  Select,
  Row,
  Col,
  Button,
  Card,
  PageHeader,
  Input,
  Form,
  InputNumber,
  Divider,
  Typography,
  DatePicker
} from "antd";
import moment from "moment";

import { DeleteOutlined } from "@ant-design/icons";
import { errorMsg, toConvertDate } from "../../../Utils/Utils";
import { toSavePaiementFournisseur } from "../APIs";

const { Option } = Select;

const PaymentFournisseur = ({
  factureToPay,
  visible,
  closePaymentModal,
  toSavePayment
}) => {
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [form] = Form.useForm();
  const [reste, setReste] = useState(0);
  const espece = { montant: reste, type: 0 };
  const cheque = { banque: "", date: 0, num: "", montant: reste, type: 1 };
  const traite = { date: 0, num: "", montant: reste, type: 2 };

  const deleteElement = (i) => {
    let items = [...selectedPayment];
    items[i]["montant"] = 0;
    items.splice(i, 1);
    setSelectedPayment(items);
    setReste(getReste());
  };
  useEffect(() => {
    form.setFieldsValue({
      montant: factureToPay.total,
      reste: getResteFirstTime()
    });
    setReste(getResteFirstTime());
  }, [factureToPay, visible]);

  const getResteFirstTime = () => {
    setSelectedPayment([]);
    if (!factureToPay) return 0;
    var res = [];
    if (!Array.isArray(factureToPay.reglements)) {
      return factureToPay.total;
    }
    factureToPay.reglements.forEach((reglement) => {
      null != reglement.date && (reglement.date = moment(reglement.date));
      res = [...res, reglement];
    });
    setSelectedPayment(res);
    return (
      factureToPay.total - res.reduce((a, b) => b.montant + a, 0)
    ).toFixed(3);
  };
  const getReste = () => {
    if (!factureToPay) return 0;

    return (
      factureToPay.total - selectedPayment.reduce((a, b) => b.montant + a, 0)
    ).toFixed(3);
  };

  const onChange = (i, attribut, value) => {
    let items = [...selectedPayment];
    items[i][attribut] = value;
    setSelectedPayment(items);
    setReste(getReste());
  };

  const validatePayment = () => {
    if (verifyFields()) {
      var l__json = { id: factureToPay.id, items: selectedPayment };

      toSavePaiementFournisseur(l__json).then((res) => {
        var result = JSON.parse(res.data.success.reglement);

        toSavePayment(res.reglement);
      });
    } else {
      errorMsg("Merci de vérifier que tout les champs sont remplis!");
    }
  };
  const verifyFields = () => {
    var res = true;
    selectedPayment.map((element) => {
      switch (element.type) {
        case 0:
        case 3:
        case 4:
        case 5:
        case 6:
          if (element.montant == 0) {
            res = false;
          }
          break;
        case 1:
          if (
            element.montant == 0 ||
            element.banque == "" ||
            element.num == 0 ||
            element.date == "" ||
            element.nom == ""
          ) {
            res = false;
          }
          break;
        case 2:
          if (element.montant == 0 || element.num == 0 || element.date == "") {
            res = false;
          }
          break;

        default:
          break;
      }
    });
    return res;
  };

  return (
    <>
      <Modal
        className="modal-large "
        visible={visible}
        title={
          <Row
            gutter={24}
            style={{
              marginTop: "2vh",
              backgroundColor: "#00aeef",
              color: "#fff",
              minHeight: "5vh",
              textAlign: "center"
            }}
          >
            <Col span={4} style={{ marginTop: "1vh", marginLeft: "1vh" }}>
              {toConvertDate(factureToPay.createdDate)}
            </Col>
            <Col span={4} style={{ marginTop: "1vh" }}>
              {factureToPay.s__ref}
            </Col>
            <Col span={4} style={{ marginTop: "1vh" }}>
              {factureToPay.providerName}
            </Col>
            <Col span={4} style={{ marginTop: "1vh" }}>
              <b>Montant Total:</b> {factureToPay.total}
            </Col>

            <Col span={4} style={{ marginTop: "1vh" }}>
              <b>Reste:</b> {reste}
            </Col>
          </Row>
        }
        onCancel={closePaymentModal}
        onOk={validatePayment}
      >
        <div className="auto--overflow">
          <PageHeader>
            <Form form={form}>
              <Row justify="space-between">
                <Col span={10}>
                  <Form.Item name="montant" label="Montant total">
                    <Input
                      disabled={true}
                      addonAfter="DT"
                      defaultValue={factureToPay.total}
                    />
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={10}>
                  <Form.Item label="Reste" name="reste">
                    <Input disabled={true} addonAfter="DT" value={reste} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </PageHeader>

          <Row justify="space-between">
            <Col span={5}>
              <Card style={{ border: "0px solid transparent" }}>
                <Button
                  size="large"
                  type="primary"
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    textAlign: "left"
                  }}
                  onClick={() =>
                    setSelectedPayment([...selectedPayment, espece])
                  }
                >
                  Espèces
                </Button>
                <br />
                <Button
                  size="large"
                  type="primary"
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    textAlign: "left"
                  }}
                  onClick={() =>
                    setSelectedPayment([...selectedPayment, cheque])
                  }
                >
                  Chèque
                </Button>
                <br />

                <Button
                  size="large"
                  type="primary"
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    textAlign: "left"
                  }}
                  onClick={() =>
                    setSelectedPayment([...selectedPayment, traite])
                  }
                >
                  Traites
                </Button>
                <br />

                <Button
                  size="large"
                  type="primary"
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    textAlign: "left"
                  }}
                  onClick={() =>
                    setSelectedPayment([
                      ...selectedPayment,
                      { montant: 0, type: 3 }
                    ])
                  }
                >
                  Carte bancaire
                </Button>
                <br />
                <Button
                  size="large"
                  type="primary"
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    textAlign: "left"
                  }}
                  onClick={() =>
                    setSelectedPayment([
                      ...selectedPayment,
                      { montant: 0, type: 4 }
                    ])
                  }
                >
                  Virement bancaire
                </Button>
                <br />
                <Button
                  size="large"
                  type="primary"
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    textAlign: "left"
                  }}
                  disabled={factureToPay.total < 1000}
                  onClick={() =>
                    setSelectedPayment([
                      ...selectedPayment,
                      {
                        montant: (factureToPay.total * 99) / 100,
                        type: 5
                      }
                    ])
                  }
                >
                  Retenu à la source
                </Button>
                <br />
                <Button
                  onClick={() =>
                    setSelectedPayment([
                      ...selectedPayment,
                      { montant: 0, type: 6 }
                    ])
                  }
                  size="large"
                  type="primary"
                  style={{
                    marginBottom: "5px",
                    width: "100%",
                    textAlign: "left"
                  }}
                >
                  Contre remboursement
                </Button>
              </Card>
            </Col>
            <Col span={16} style={{ overflowY: "auto", maxHeight: "40vh" }}>
              {selectedPayment.filter((e) => e.type === 0).length > 0 && (
                <>
                  <Typography.Title level={5}> Espèces</Typography.Title>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Montant</th>
                      </tr>
                    </thead>
                    {selectedPayment.map((element, i) => (
                      <>
                        {element.type == 0 && (
                          <tr>
                            <td
                              className="ant-table-cell"
                              style={{ width: "40px" }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteElement(i)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                onChange={(e) => onChange(i, "montant", e)}
                                value={element.montant}
                                decimalSeparator={","}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </table>
                  <Divider />
                </>
              )}

              {selectedPayment.filter((e) => e.type === 1).length > 0 && (
                <>
                  <Typography.Title level={5}> Chèques</Typography.Title>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Nom</th>
                        <th className="ant-table-cell">Montant</th>
                        <th className="ant-table-cell">Num</th>
                        <th className="ant-table-cell">Banque</th>
                        <th className="ant-table-cell">Date</th>
                      </tr>
                    </thead>
                    {selectedPayment.map((element, i) => (
                      <>
                        {element.type == 1 && (
                          <tr>
                            <td className="ant-table-cell">
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteElement(i)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <Input
                                value={element.nom}
                                onChange={(e) =>
                                  onChange(i, "nom", e.target.value)
                                }
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                decimalSeparator={","}
                                value={element.montant}
                                onChange={(e) => onChange(i, "montant", e)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                value={element.num}
                                onChange={(e) => onChange(i, "num", e)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <Input
                                value={element.banque}
                                onChange={(e) =>
                                  onChange(i, "banque", e.target.value)
                                }
                              />
                            </td>
                            <td className="ant-table-cell">
                              <DatePicker
                                value={element.date}
                                onChange={(e) => onChange(i, "date", e)}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </table>
                  <Divider />
                </>
              )}

              {selectedPayment.filter((e) => e.type === 2).length > 0 && (
                <>
                  <Typography.Title level={5}> Traites</Typography.Title>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Montant</th>
                        <th className="ant-table-cell">Num</th>
                        <th className="ant-table-cell">Date</th>
                      </tr>
                    </thead>
                    {selectedPayment.map((element, i) => (
                      <>
                        {element.type == 2 && (
                          <tr>
                            <td className="ant-table-cell">
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteElement(i)}
                              />
                            </td>

                            <td className="ant-table-cell">
                              <InputNumber
                                decimalSeparator={","}
                                value={element.montant}
                                onChange={(e) => onChange(i, "montant", e)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                value={element.num}
                                onChange={(e) => onChange(i, "num", e)}
                              />
                            </td>

                            <td className="ant-table-cell">
                              <DatePicker
                                value={element.date}
                                onChange={(e) => onChange(i, "date", e)}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </table>
                  <Divider />
                </>
              )}

              {selectedPayment.filter((e) => e.type === 3).length > 0 && (
                <>
                  <Typography.Title level={5}> Carte bancaire</Typography.Title>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Montant</th>
                      </tr>
                    </thead>
                    {selectedPayment.map((element, i) => (
                      <>
                        {element.type == 3 && (
                          <tr>
                            <td
                              className="ant-table-cell"
                              style={{ width: "40px" }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteElement(i)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                onChange={(e) => onChange(i, "montant", e)}
                                value={element.montant}
                                decimalSeparator={","}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </table>
                  <Divider />
                </>
              )}
              {selectedPayment.filter((e) => e.type === 4).length > 0 && (
                <>
                  <Typography.Title level={4}>
                    {" "}
                    Virement bancaire
                  </Typography.Title>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Montant</th>
                      </tr>
                    </thead>
                    {selectedPayment.map((element, i) => (
                      <>
                        {element.type == 4 && (
                          <tr>
                            <td
                              className="ant-table-cell"
                              style={{ width: "40px" }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteElement(i)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                onChange={(e) => onChange(i, "montant", e)}
                                value={element.montant}
                                decimalSeparator={","}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </table>
                  <Divider />
                </>
              )}
              {selectedPayment.filter((e) => e.type === 5).length > 0 && (
                <>
                  <Typography.Title level={5}>
                    Retenu à la source
                  </Typography.Title>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Montant</th>
                      </tr>
                    </thead>
                    {selectedPayment.map((element, i) => (
                      <>
                        {element.type == 5 && (
                          <tr>
                            <td
                              className="ant-table-cell"
                              style={{ width: "40px" }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteElement(i)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                onChange={(e) => onChange(i, "montant", e)}
                                value={element.montant}
                                decimalSeparator={","}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </table>
                  <Divider />
                </>
              )}
              {selectedPayment.filter((e) => e.type === 6).length > 0 && (
                <>
                  <Typography.Title level={5}>
                    Contre remboursement
                  </Typography.Title>
                  <table className="ant-table" style={{ width: "100%" }}>
                    <thead className="ant-table-thead">
                      <tr>
                        <th className="ant-table-cell"></th>
                        <th className="ant-table-cell">Montant</th>
                      </tr>
                    </thead>
                    {selectedPayment.map((element, i) => (
                      <>
                        {element.type == 6 && (
                          <tr>
                            <td
                              className="ant-table-cell"
                              style={{ width: "40px" }}
                            >
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => deleteElement(i)}
                              />
                            </td>
                            <td className="ant-table-cell">
                              <InputNumber
                                onChange={(e) => onChange(i, "montant", e)}
                                value={element.montant}
                                decimalSeparator={","}
                              />
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </table>
                  <Divider />
                </>
              )}
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};
export default PaymentFournisseur;
