import { Button, Col, Form, Input, Modal, Popconfirm, Row, Typography, Skeleton, InputNumber } from "antd";
import { useState, useEffect } from "react";
import { toGetCategoryList, toDeleteCategory, toAddOrUpdateCategory } from "./Api";
import { PlusOutlined } from "@ant-design/icons";

import { BiPencil, BiTrash } from "react-icons/bi";
import moment from "moment";
import { errorMsg, successMsg } from "../../Utils/Utils";
import localization from "moment/locale/fr";
import "../../Styles/TwoTables.scss";

moment.updateLocale("fr", localization);

const Category = () => {
  const [loading, setLoading] = useState(true);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [Categorys, setCategorys] = useState([]);
  const [category, setCategory] = useState("");
  const [marge, setMarge] = useState(0);
  const [titleTecdoc, setTitleTecdoc] = useState("");
  const [id, setId] = useState(-1);
  const [form] = Form.useForm();

  const closeCreateModal = () => {
    if (category == "") {
      errorMsg("veuillez remplir tous les champs obligatoires!");
      return;
    }
    toAddOrUpdateCategory(category, titleTecdoc, id, marge).then((res) => {
      successMsg(id != -1 ? "Category mis à jours avec succées!" : "Category ajoutée avec succées!");
      if (id == -1) {
        setCategorys([...Categorys, res]);
      } else {
        const categoryIndex = Categorys.findIndex((c) => c.id == id);
        const newCategorys = [...Categorys];
        newCategorys[categoryIndex] = { ...Categorys[categoryIndex], ...res };
        setCategorys(newCategorys);
      }
    });
    setId(-1);
    setCreateModalVisible(false);
    setCategory("");
    setTitleTecdoc("");
  };

  useEffect(() => {
    toGetDatas();
  }, []);
  const toGetDatas = () => {
    toGetCategoryList().then((res) => {
      setCategorys(res);
      setLoading(false);
    });
  };
  const toDelete = (id) => {
    toDeleteCategory(id).then((res) => {
      let newCategorys = Categorys.filter((c) => c.id != id);
      setCategorys(newCategorys);
    });
  };

  const toEdit = (id, category, titleTecdoc, marge) => {
    setId(id);
    setTitleTecdoc(titleTecdoc);
    setCategory(category);
    setMarge(marge);
    form.setFieldsValue({ category: category, titleTecdoc: titleTecdoc, marge });
    setCreateModalVisible(true);
  };
  const toAdd = () => {
    setId(-1);
    setCategory("");
    setTitleTecdoc("");
    setMarge(0);
    form.setFieldsValue({ category: "", titleTecdoc: "", marge: 0 });

    setCreateModalVisible(true);
  };
  const exitCreateModal = () => {
    setId(-1);
    setCategory("");
    setTitleTecdoc("");
    setMarge(0);
    setCreateModalVisible(false);
  };

  return (
    <>
      <Row>
        <Col span="12">
          <Typography.Title level="2">Catégories</Typography.Title>
        </Col>
        <Col span="12" style={{ textAlign: "right" }}>
          <Button icon={<PlusOutlined />} onClick={(e) => toAdd()} type="primary" size="large">
            Ajouter une Catégorie
          </Button>
        </Col>
      </Row>

      <Row
        style={{
          overflowY: "hidden",
        }}
      >
        <Col span="24">
          <div className="table-wrapper auto--overflow">
            <table className="ant-table" style={{ width: "100%" }}>
              <thead className="ant-table-thead">
                <tr>
                  <th className="ant-table-cell">Date création</th>
                  <th className="ant-table-cell">Catégorie</th>
                  <th className="ant-table-cell">Catégorie Tecdoc</th>
                  <th className="ant-table-cell">Marge</th>
                  <th className="ant-table-cell">Crée par</th>
                  <th className="ant-table-cell"></th>
                </tr>
              </thead>
              <Skeleton active loading={loading} />

              <tbody className="ant-table-tbody ">
                {null != Categorys &&
                  Categorys.length > 0 &&
                  Categorys.map((Category, i) => (
                    <tr key={i}>
                      <td className="ant-table-cell">{moment(Category.createdDate).format("D MMMM YYYY")}</td>
                      <td className="ant-table-cell">{Category.title}</td>
                      <td className="ant-table-cell">{Category.categoryTecdoc}</td>
                      <td className="ant-table-cell">{Category.marge}</td>
                      <td className="ant-table-cell">{Category.createdBy}</td>
                      <td className="ant-table-cell" style={{ float: "right" }}>
                        <Button onClick={() => toEdit(Category.id, Category.title, Category.categoryTecdoc)} icon={<BiPencil />} />
                        <Popconfirm
                          title={`Voulez vous vraiment supprimer ${Category.title}?`}
                          onConfirm={() => toDelete(Category.id)}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <Button icon={<BiTrash />} />
                        </Popconfirm>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </Col>
      </Row>
      <Modal
        title={id != -1 ? "Modifier une Category" : "Ajouter une nouvelle Category"}
        visible={createModalVisible}
        onOk={closeCreateModal}
        onCancel={exitCreateModal}
      >
        <Form name="basic" form={form}>
          <Form.Item label="Catégorie" name="category" rules={[{ required: true, message: "Champ obligatoire" }]}>
            <Input onChange={(e) => setCategory(e.target.value)} />
          </Form.Item>

          <Form.Item label="Catégorie tecdoc" name="titleTecdoc">
            <Input onChange={(e) => setTitleTecdoc(e.target.value)} />
          </Form.Item>
          <Form.Item label="Marge" name="marge">
            <InputNumber onChange={(e) => setMarge(e)} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Category;
