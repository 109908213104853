import CAxios from "../../Utils/CAxios";
import printJS from "print-js";
import moment from "moment";
import localization from "moment/locale/fr";
moment.updateLocale("fr", localization);
const ls = require("localstorage-ttl");

export const toCreatePurshaseOrder = (
  purshaseOrderProvider,
  purshaseOrderItems,
  purshaseOrderDiscount
) => {
  const purshaseOrder = {
    provider: { id: purshaseOrderProvider.id },
    d__discount: purshaseOrderDiscount
  };
  const purshaseOrder_items = purshaseOrderItems.map((item) => {
    return {
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      article: { id: item.id }
    };
  });
  return CAxios.post("/api/purshaseorder/create", {
    purshaseOrder,
    purshaseOrder_items
  });
};

export const toEditPurshaseOrder = (
  purshaseOrderId,
  purshaseOrderProvider,
  purshaseOrderItems,
  purshaseOrderDiscount
) => {
  const purshaseOrder = {
    id: purshaseOrderId,
    provider: { id: purshaseOrderProvider.id },
    d__discount: purshaseOrderDiscount
  };
  const purshaseOrder_items = purshaseOrderItems.map((item) => {
    return {
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      article: { id: null != item.article ? item.article.id : item.id }
    };
  });
  return CAxios.post("/api/purshaseorder/edit", {
    purshaseOrder,
    purshaseOrder_items
  });
};

export const toDeleteBR = async (id) => {
  const { data } = await CAxios.get(`/api/br/delete/${id}`);
  return data.success;
};

export const toGetPurshaseOrder = async (id) => {
  const { data } = await CAxios.get(`/api/purshaseorder/detail/${id}`);
  return data.success && data.success.PurshaseOrder
    ? JSON.parse(data.success.PurshaseOrder)
    : null;
};
export const toPrintPurshaseOrder = async (id, titre1) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  try {
    const { data } = await CAxios.get(
      `/api/purshaseorder/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    console.log(e);
    return null;
  }
};
export const toPrintPurshaseOrderWithEntete = async (id, titre1) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  try {
    const { data } = await CAxios.get(
      `/api/purshaseorderentete/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const toSavePaiementFournisseur = (data) => {
  return CAxios.post("/api/reglement_fournisseur/add", data);
};

export const toPrintPurshaseOrderEmployee = async (id) => {
  try {
    const { data } = await CAxios.get(
      `/api/purshase_order_employee/print/${id}`,
      {
        responseType: "blob",
        timeout: 300000
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const toUpdatePurshaseOrderData = async (l__data) => {
  const { data } = await CAxios.post(
    "/api/purshaseorder/update",
    JSON.stringify(l__data)
  );
  return data.success && data.success.quote ? data.success.quote : null;
};

export const toGetPurshaseOrders = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post(`/api/purshaseorder/list`, {
      perPage: perPage,
      page: page,
      options: options
    });
    return data.success.purshaseOrders;
  } catch (e) {
    return null;
  }
};
export const toGetTotalPO = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post(`/api/purshaseorder/total`, {
      perPage: perPage,
      page: page,
      options: options
    });
    return data.success.purshaseOrders;
  } catch (e) {
    return null;
  }
};

export const toPrintBR = async (id, titre1) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  try {
    const { data } = await CAxios.get(`/api/br/print/${"[" + inputs + "]"}`, {
      responseType: "blob",
      timeout: 300000
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintBRWithEntete = async (id, titre1) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(titre1);
  try {
    const { data } = await CAxios.get(
      `/api/brentete/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toPrintBRs = async (ids) => {
  try {
    const { data } = await CAxios.get(`/api/brs/print/${"[" + ids + "]"}`, {
      responseType: "blob",
      timeout: 300000
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toGetBonsReception = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post(`/api/br/list`, {
      perPage: perPage,
      page: page,
      options: options
    });
    return null != data.success ? data.success.bonsreception : null;
  } catch (e) {
    return null;
  }
};

export const toGetTotalBrs = async (options) => {
  try {
    const { data } = await CAxios.post(`/api/br/detail`, {
      options: options
    });
    return data.success && data.success.brs ? data.success.brs : [];
  } catch (e) {
    return null;
  }
};

export const toGetTotalFactures = async (options) => {
  try {
    const { data } = await CAxios.post(`/api/facture_reception/detail`, {
      options: options
    });
    console.log(data);
    return data.success && data.success.FactureReceptions
      ? data.success.FactureReceptions
      : [];
  } catch (e) {
    return null;
  }
};

export const toGetInventories = async (entityId) => {
  try {
    const { data } = await CAxios.post(`/api/inventory/list`, {
      entityId: entityId
    });
    return null != data.success ? data.success.inventories : null;
  } catch (e) {
    return null;
  }
};

export const toSynchronizeInventory = async (inventory, entityId) => {
  try {
    const { data } = await CAxios.post(`/api/inventory/synchronize`, {
      inventory,
      entityId: entityId
    });
    return null != data.success ? data.success.inventories : null;
  } catch (e) {
    return null;
  }
};

export const toGetBsList = async (entityId) => {
  try {
    const { data } = await CAxios.post(`/api/bs/list`, {
      entityId: entityId
    });
    return null != data.success ? data.success.bs : null;
  } catch (e) {
    return null;
  }
};

export const toGetBeList = async (entityId) => {
  try {
    const { data } = await CAxios.post(`/api/be/list`, {
      entityId: entityId
    });
    return null != data.success ? data.success.be : null;
  } catch (e) {
    return null;
  }
};

export const toDeleteInventory = async (id) => {
  try {
    const { data } = await CAxios.post(`/api/inventory/delete`, {
      id: id
    });
    return null != data.success ? data.success.inventories : null;
  } catch (e) {
    return null;
  }
};

export const toGetStock = async (entityId) => {
  try {
    const { data } = await CAxios.post(`/api/stock/all`, {
      entityId: entityId
    });
    return null != data.success ? data.success.stock : null;
  } catch (e) {
    return null;
  }
};

export const toGetBonReception = async (id) => {
  const { data } = await CAxios.get(`/api/br/detail/${id}`);
  return data.success && data.success.bonreception
    ? JSON.parse(data.success.bonreception)
    : null;
};

export const toGetInventory = async (id) => {
  const { data } = await CAxios.post(`/api/inventory/detail`, { id: id });
  return data.success && data.success.inventory ? data.success.inventory : null;
};

export const toGetBs = async (id) => {
  const { data } = await CAxios.post(`/api/bs/detail`, { id: id });
  return data.success && data.success.bs ? data.success.bs : null;
};

export const toGetBe = async (id) => {
  const { data } = await CAxios.post(`/api/be/detail`, { id: id });
  return data.success && data.success.be ? data.success.be : null;
};

export const toCreateInventory = async (id, articles, entityId) => {
  return CAxios.post("/api/inventory/add", {
    emplacementId: id,
    articles: articles,
    entityId: entityId
  });
};

export const toCreateBs = async (articles, entityId) => {
  const { data } = await CAxios.post("/api/bs/add", {
    articles: articles,
    entityId: entityId
  });
  return data.success.bs;
};

export const toCreateBe = async (articles, entityId) => {
  const { data } = await CAxios.post("/api/be/add", {
    articles: articles,
    entityId: entityId
  });
  return data.success.be;
};

export const toCreateBR = (
  provider,
  items,
  discount,
  total,
  s__blProvider,
  s__blDate,
  moyenne,
  checked,
  toEdit,
  id,
  active,
  fodec
) => {
  let tva = 0;
  const bonReception_items = items.map((item) => {
    tva += (item.uPriceTTC - item.uPriceHt) * item.quantity;
    return {
      d__marge: item.marge,
      d__discount: item.discount,
      d__quantity: item.quantity,
      d__unitPriceHT: item.uPriceHt,
      d__unitPriceTTC: item.uPriceTTC,
      d__tva: item.uPriceTTC - item.uPriceHt,
      d__total: item.total,
      article: { id: null != item.article ? item.article.id : item.id }
    };
  });
  const bonReception = {
    id: id,
    provider: { id: provider.id },
    s__blProvider: s__blProvider,
    s__blDate: moment(s__blDate).format("L"),
    d__discount: discount,
    d__total: total,
    d__tva: tva
  };
  var url = "/api/br/create";
  if (toEdit) {
    url = "/api/br/update";
  }
  return CAxios.post(url, {
    bonReception,
    moyenne,
    bonReception_items,
    toUpdatePrice: checked,
    active,
    fodec: fodec ? fodec : false,
  });
};

export const toGetProviderList = async () => {
  try {
    const { data } = await CAxios.get(`/api/providers/list`);
    ls.set(`providers`, data.success.providers);
    return data.success.providers;
  } catch (e) {
    return null;
  }
};

export const toAddOrUpdateProvider = async (provider) => {
  var url = provider.id != -1 ? "/api/providers/update" : "/api/providers/add";

  const { data } = await CAxios.post(url, JSON.stringify(provider));
  return data.success && data.success.provider ? data.success.provider : null;
};

export const toEditProvider = async (provider) => {
  await CAxios.post(`/api/providers/edit`, provider);
};

export const toDeleteProvider = async (providerId) => {
  await CAxios.post(`/api/providers/delete/${providerId}`);
};

export const toCreateFacture = (brs, data) => {
  var ids = [];
  brs.forEach((br) => {
    ids.push(br.id);
  });
  return CAxios.post("/api/factures_reception/create", {
    bonsreception: ids,
    data: data
  });
};

export const toGetBRsNonFacture = async (id) => {
  const { data } = await CAxios.post("/api/brs/facture", { id: id });
  return data.success && data.success.bonsreception
    ? data.success.bonsreception
    : [];
};
export const toGetFactures = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post("/api/factures_reception/list", {
      perPage: perPage,
      page: page,
      options: options
    });
    return data.success.FactureReceptions;
  } catch (e) {
    return null;
  }
};
export const toGetFacturesAllAchat = async (page, perPage, options) => {
  try {
    const { data } = await CAxios.post("/api/factures_reception/all", {
      perPage: perPage,
      page: page,
      options: options
    });
    return data.success.FactureReceptions;
  } catch (e) {
    return null;
  }
};
export const toPrintFacture = async (id, displayFooter, detail) => {
  var inputs = [];
  inputs.push(id);
  inputs.push(displayFooter);
  inputs.push(detail);
  try {
    const { data } = await CAxios.get(
      `/api/factures_reception/print/${"[" + inputs + "]"}`,
      {
        responseType: "blob",
        timeout: 300000
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};
export const toPrintReglementFacture = async (id) => {
  try {
    const { data } = await CAxios.get(
      `/api/facture_reglements/print/${id}`,
      {
        responseType: "blob",
        timeout: 300000
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};



export const toPrintFactureForEmployee = async (id) => {
  try {
    const { data } = await CAxios.get(
      `/api/factures_reception/print_employee/${id}`,
      {
        responseType: "blob",
        timeout: 300000
      }
    );
    const url = window.URL.createObjectURL(new Blob([data]));
    printJS(url, "pdf");
  } catch (e) {
    return null;
  }
};

export const toDeleteFacture = async (id) => {
  const { data } = await CAxios.post(`/api/factures_reception/delete`, {
    id: id
  });
  return null != data.success && data.success;
};

export const toGetFactureReception = async (id) => {
  try {
    const { data } = await CAxios.get("/api/factures_reception/" + id);
    return JSON.parse(data.success.FactureReception);
  } catch (e) {
    return null;
  }
};

export const toGetReglements = async () => {
  try {
    const { data } = await CAxios.get("/api/reglement_fournisseur/list");
    return data.success.reglements;
  } catch (e) {
    return null;
  }
};

export const toDeleteReglement = async (id) => {
  try {
    const { data } = await CAxios.post("/api/reglement_fournisseur/delete", {
      id: id
    });
    return true;
  } catch (e) {
    return null;
  }
};

export const getIds = async () => {
  const { data } = await CAxios.post(`/api/facture_reception/ids`);
  return data.success && data.success.ids ? data.success.ids : null;
};

export const toGenerateFacture = async (ids, dateFacture, date) => {
  const { data } = await CAxios.post(`/api/facture/generate`, {
    clientsIds: ids,
    dateFacture,
    date
  });
  return data.success && data.success.facture ? data.success.facture : false;
};

export const toGetStatProviders = async () => {
  const { data } = await CAxios.post(`/api/provider/stat`, {});
  return data.success ? data.success : null;
};
