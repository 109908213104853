import { Typography, Select, Row, Col, Form, DatePicker, Skeleton, Tag } from "antd";
import { useEffect, useState } from "react";
import { toGetQuotesBLEntities, toGetTotalEntities, toGetTotalUser } from "./APIs";
import moment from "moment";
import {
  ComposedChart,
  Line,
  Bar,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  BarChart,
  LabelList,
} from "recharts";
import Loading from "react-loading-bar";

import { toGetEntityList, toGetUsers } from "../Compte/Api";
import StatsClient from "./StatsClient";
import StatsTechniciens from "./StatsTechniciens";
const { RangePicker } = DatePicker;

const { Option } = Select;
const colors = [
  "#e6194b",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#ffffff",
  "#000000",
];

const StatsUser = ({ AppStore, ...props }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [all, setAll] = useState([]);
  const [selected, setSelectedUser] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [y, setY] = useState(0);
  const [max, setMax] = useState(10000);
  const [displayDate, setDisplayDate] = useState(false);
  const [displayDateRange, setDisplayDateRange] = useState(false);
  const [Entitys, setEntitys] = useState([]);
  const [entityIds, setEntityIds] = useState([]);
  const [selectedDate, setSelectedDate] = useState(0);
  const [type, setSelectedType] = useState("1");

  useEffect(() => {
    AppStore.setFirst(2);
    AppStore.setSecond(14);

    toGetUsers().then((users) => {
      setUsers(users.filter((e) => e.active));
    });
    toGetEntityList().then((res) => {
      setEntitys(res);
      var l__ids = [];
      res.forEach((entity) => l__ids.push(entity.id));
      setEntityIds(l__ids);
      getDataEntities(0, l__ids, type, selected);
    });
  }, []);

  const getData = (date, entityIds) => {
    setData([]);
    getDataEntities(date, entityIds, type, selected);
    return;
    // setLoading(true);
    // setStart(start);
    // setEnd(end);

    // toGetTotalUser(start, end, ids).then((res) => {
    //   setAll(res);
    //   var results = [];
    //   var total = 0;
    //   res.forEach((element) => {
    //     results.push({ date: element.date, total: element.total });
    //     total += parseFloat(element.total);
    //   });
    //   setMax(Math.ceil(getMax(res) + 500));
    //   setTimeout(() => {
    //     setData(res);
    //     setLoading(false);
    //     setY(total / results.length);
    //   }, 500);
    // });
  };

  const getDataEntities = (date, entityIds, type, usersIds) => {
    setLoading(true);
    console.log(usersIds);
    const entity = usersIds.length === 0;
    switch (type) {
      case "1": //chiffre d'affaires
        toGetTotalEntities(date, entity ? entityIds : usersIds, entity).then((res) => {
          setData(res);
          setAll(res);
          setLoading(false);
        });
        break;

      case "2": //devis convertis en bl
        toGetQuotesBLEntities(date, entity ? entityIds : usersIds, entity).then((res) => {
          setData(res);
          setAll(res);
          setLoading(false);
        });
        break;

      default:
        break;
    }
  };
  const onChangeUsers = (option) => {
    setData([]);
    var ids = [];
    if (option.length == 0) {
      setSelectedUser([]);
      getDataEntities(selectedDate, entityIds, type, ids);

      return;
    }
    option.forEach((element) => {
      if (element != "0") {
        ids.push(users.filter((el) => el.username == element)[0].id);
      }
    });
    setSelectedUser(ids);
    getDataEntities(selectedDate, entityIds, type, ids);
  };

  const onChangeDate = (option) => {
    setSelectedDate(option);
    getData(option, entityIds);
  };
  const getUsername = (el) => {
    if (selected.length === 0) {
      return Entitys.filter((e) => e.id == el)[0].title;
    }

    return users.filter((e) => e.id == el)[0].username;
  };

  const toGetTotal = (id) => {
    let total = 0;
    data.forEach((el) => {
      total += parseFloat(el[getUsername(id)]);
    });
    if (type == "2") {
      return Math.round(total / data.length);
    }
    return total.toFixed(3).replace(/\d(?=(\d{3})+\.)/g, "$& ");
  };

  return (
    <>
      <Loading show={loading} color="red" showSpinner={true} />

      <Row className="auto--custom-header">
        <Col span="3">
          <Typography.Title level={3}>Statistiques </Typography.Title>
        </Col>
        <Col span="4">
          <Select
            showSearch
            style={{ width: "80%" }}
            defaultValue={"1"}
            onChange={(e) => {
              setSelectedType(e);
              getDataEntities(selectedDate, entityIds, e, selected);
            }}
          >
            <Option value="1">Chiffre d'affaires</Option>
            <Option value="2">Devis convertis en BL</Option>
            <Option value="3">Absence</Option>
          </Select>
        </Col>
        <Col span="4">
          <Form.Item label="Date">
            <Select showSearch onSelect={onChangeDate} style={{ width: "100%" }} defaultValue={"0"}>
              <Option value="0">Derniers 10 jrs</Option>
              <Option value="1">Ce mois</Option>
              <Option value="2">Cette année</Option>
              <Option value="3">Année</Option>
            </Select>
          </Form.Item>
          {displayDate && <DatePicker style={{ width: "100%" }} />}
          {displayDateRange && <DatePicker.RangePicker />}
        </Col>

        <Col span="5">
          <Form.Item label="Utilisateur">
            {users.length > 0 && (
              <Select showSearch mode="multiple" onChange={onChangeUsers} style={{ width: "80%" }}>
                {users
                  .filter((a) => a.active)
                  .map((u) => (
                    <Option value={u.username}>{u.username}</Option>
                  ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span="5">
          <Form.Item label="Magasins">
            {entityIds.length > 0 && (
              <Select
                disabled={selected.length > 0}
                onChange={(e) => {
                  setEntityIds(e);
                  getDataEntities(selectedDate, e, type, selected);
                }}
                style={{ width: "100%" }}
                placeholder="Séléctionner l'entité"
                defaultValue={entityIds}
                mode="multiple"
              >
                {Entitys.filter((e) => e.id != 5).map((e) => (
                  <Select.Option value={e.id}>{e.title}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
      <Skeleton active loading={loading} />

      {data.length > 0 && (
        <>
          <Row className="auto--custom-header">
            <Col span="24">
              <div style={{ height: 600, marginTop: "4vh" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 50,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                    <XAxis dataKey="date" />
                    <YAxis type="number" dataKey={(v) => parseInt(v.valeur)} domain={[0, "dataMax + 100"]} />

                    <Tooltip />
                    {selected.length > 0 &&
                      selected.map((el, i) => (
                        <Bar type="monotone" dataKey={getUsername(el)} stroke={colors[i]} fill={colors[i]} stackId={i}>
                          {/* <LabelList dataKey={getUsername(el)} content={renderCustomizedLabel} /> */}
                        </Bar>
                      ))}
                    {selected.length === 0 &&
                      Entitys.map((el, i) => (
                        <Bar type="monotone" dataKey={el.title} stroke={colors[i]} fill={colors[i]} stackId={i}>
                          {/* <LabelList dataKey={el.title} content={renderCustomizedLabel} /> */}
                        </Bar>
                      ))}
                    <Line type="monotone" dataKey="moyenne" stroke="black" />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row>
          <Row justify="center">
            {selected.length > 0 && selected.map((el, i) => <Tag color={colors[i]}>{getUsername(el) + ": " + toGetTotal(el)}</Tag>)}
            {selected.length === 0 && entityIds.map((el, i) => <Tag color={colors[i]}>{getUsername(el) + ": " + toGetTotal(el)}</Tag>)}
          </Row>
        </>
      )}
      <StatsTechniciens />
      <StatsClient />
    </>
  );
};

export default StatsUser;
