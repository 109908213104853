import { Menu, Row, Statistic, Form, Col, Button, Input, InputNumber, Typography, Popconfirm, DatePicker } from "antd";

import { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { logout } from "../Actions/AuthActions";
import { connect } from "react-redux";
import cookie from "react-cookies";
import moment from "moment";
import localization from "moment/locale/fr";
import Modal from "antd/lib/modal/Modal";
import Tecdoc from "../Utils/Tecdoc";
import { toConvertDate } from "../Utils/Utils";
import { toGetListDelivery, toRemoveDelivery } from "../Pages/Users/APIs";
import { DeleteOutlined } from "@ant-design/icons";

moment.updateLocale("fr", localization);

const { SubMenu } = Menu;

const Navigation = ({ AppStore, ...props }) => {
  const [form] = Form.useForm();
  const [current, setCurrent] = useState("Achat");
  const [coefficient, setCoefficient] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState(null != localStorage.getItem("index") ? localStorage.getItem("index") : 0);
  const [date, setDate] = useState(new Date());
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState("");
  const [listDelivery, setListDelivery] = useState([]);

  useEffect(() => {
    setInterval(() => {
      setTime(moment().format("LL HH:mm:ss"));
    }, 1000);

    window.onkeydown = (evt) => {
      switch (evt.keyCode) {
        //ESC
        case 27:
          evt.preventDefault();
          console.log("esc");
          break;
        //F1
        case 112:
          setIsModalVisible(true);
          evt.preventDefault();
          console.log("f1");
          break;
        default:
          return;
      }
    };
  }, [AppStore.first]);
  const handleClick = (e) => {
    setCurrent(e.key);
  };

  const getData = (date) => {
    toGetListDelivery(moment(date).format("YYYY-MM-DD")).then(({ list, caisses }) => {
      setListDelivery(list);
    });
  };
  const setMode = (i) => {
    setSelectedMenu(i);
    AppStore.setFirst(i);
  };

  const setBoth = (i, j) => {
    setSelectedMenu(i);

    AppStore.setFirst(i);
    AppStore.SetSecond(j);
    setIsModalVisible(false);
  };

  const toCalculateCoefficient = () => {
    var i__coefficient = 0;
    form.validateFields().then((values) => {
      i__coefficient = (values.marchandise * values.taux + values.cout + values.diouane - values.tvaDiouane) / values.marchandise;
      setCoefficient(i__coefficient);
    });
  };
  return (
    <div>
      <Row className="auto--background-grey" style={{ margin: 0 }}>
        <Col span={3}>
          <span style={{ marginLeft: "1vh", color: "#432c79" }}>
            <b>{cookie.load("nom")}</b>
          </span>
        </Col>
        <Col span={16}>
          <Menu
            onClick={handleClick}
            style={{
              textAlign: "right",
              marginRight: "25vh",
              backgroundColor: "#f0f0f0",
            }}
            selectedKeys={[current]}
            mode="horizontal"
            theme="light"
          >
            <Menu.Item
              danger={true}
              className={AppStore.first == 0 && "auto--menu-achat-background"}
              key="Achat"
              onClick={(e) => {
                setMode(0);
              }}
            >
              <Link to="/achat"> Achat</Link>
            </Menu.Item>
            <Menu.Item
              key="Vente"
              className={AppStore.first == 1 && "auto--menu-vente-background"}
              onClick={(e) => {
                setMode(1);
              }}
            >
              <Link to="/vente">Vente</Link>
            </Menu.Item>
            <Menu.Item
              key="Objectfs"
              onClick={(e) => {
                setMode(2);
              }}
            >
              <Link to="/">Objectifs & stats</Link>
            </Menu.Item>
            <SubMenu key="compte" title="Compte">
              <Menu.Item
                key="profile"
                onClick={(e) => {
                  setMode(2);
                }}
              >
                <Link to="/compte/profile">Mon profile</Link>
              </Menu.Item>
              {cookie.load("role") == "Super Admin" && (
                <SubMenu key="gestion" title="Gestion">
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="tva"
                  >
                    <Link to="/Gestions/tva">TVA</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="emplacement"
                  >
                    <Link to="/Gestions/emplacement">emplacement</Link>
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="category"
                  >
                    <Link to="/Gestions/category">catégories</Link>
                  </Menu.Item>
                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="unite"
                  >
                    <Link to="/Gestions/unite">unité</Link>
                  </Menu.Item>

                  <Menu.Item
                    onClick={(e) => {
                      setMode(2);
                    }}
                    key="marque"
                  >
                    <Link to="/Gestions/marque">marque</Link>
                  </Menu.Item>
                </SubMenu>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key="users"
                >
                  <Link to="/compte/users">Utilisateurs</Link>
                </Menu.Item>
              )}
              {
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                    setIsVisible(true);
                    getData(new Date());
                  }}
                  key="livraisons"
                >
                  <span>Livraisons</span>
                </Menu.Item>
              }

              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key="parameters"
                >
                  <Link to="/Gestions/preference">Préférences</Link>
                </Menu.Item>
              )}
              {cookie.load("role") == "Super Admin" && (
                <Menu.Item
                  onClick={(e) => {
                    setMode(2);
                  }}
                  key="corbeille"
                >
                  <Link to="/Gestions/corbeille">Corbeille</Link>
                </Menu.Item>
              )}
              <Menu.Item onClick={props.logout} key="Logout">
                Déconnexion
              </Menu.Item>
            </SubMenu>

            <Button
              type="link"
              onClick={() => setIsModalVisible(true)}
              icon={<img src="/images/detailArticle.svg" style={{ height: "90%" }} />}
            ></Button>
            {/* {cookie.load("role") == "Super Admin" && (
              <Button
                type="link"
                onClick={() => {
                  setOpen(true);
                  setCoefficient(0);
                }}
                icon={<img src="/images/coin.svg" style={{ height: "90%" }} />}
              ></Button>
            )} */}
          </Menu>
        </Col>
        <Col span={3} style={{ textAlign: "right" }}>
          <span>
            <b>{time}</b>
          </span>
        </Col>
      </Row>

      <Modal
        title="Liste des articles"
        visible={isModalVisible}
        className="smtv-modal"
        maskClosable={false}
        onCancel={(e) => setIsModalVisible(false)}
        footer={[]}
        width="180vh"
      >
        <Tecdoc displayTitle={true} addItem={null} reference={""} achat={false} />{" "}
      </Modal>
      <Modal title="Calcul prix" visible={open} onCancel={() => setOpen(false)} footer={[]}>
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Valeur de la marchandise en dollar"
            name="marchandise"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <Form.Item
            label="Taux de conversion"
            name="taux"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <Form.Item
            label="Coût de transport"
            name="cout"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <Form.Item
            label="Diouane"
            name="diouane"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>

          <Form.Item
            label="TVA Diouane"
            name="tvaDiouane"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber style={{ width: "200px" }}></InputNumber>
          </Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button type="primary" onClick={() => toCalculateCoefficient()}>
              Calculer le coefficint
            </Button>
          </div>
          <Statistic title={"Le coffiecient est"} value={coefficient} precision={3} />
        </Form>
      </Modal>

      <Modal title="La liste des livraisons" className="smtv-modal" width="60%" onCancel={() => setIsVisible(false)} visible={isVisible}>
        <Row>
          <Row className="auto--custom-header">
            <Col span="24">
              <Form.Item label="Date">
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(e, date) => {
                    getData(date);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <table className="ant-table" style={{ width: "100%" }}>
            <thead className="ant-table-thead">
              <tr>
                <th className="ant-table-cell">Date</th>
                <th className="ant-table-cell">Référence BL/BR</th>
                <th className="ant-table-cell">Chauffeur</th>
                <th className="ant-table-cell">Crée par</th>
                <th className="ant-table-cell"></th>
              </tr>
            </thead>
            <tbody className="ant-table-tbody">
              {listDelivery.map((el) => (
                <tr>
                  <td className="ant-table-cell">{toConvertDate(el.createdDate)}</td>
                  <td className="ant-table-cell">
                    {el.blId != -1 ? (
                      <Typography.Link level={5} style={{ cursor: "pointer" }}>
                        BL: {el.bl}
                      </Typography.Link>
                    ) : (
                      <Typography.Link level={5}>BR: {el.br}</Typography.Link>
                    )}
                  </td>
                  <td className="ant-table-cell">
                    <b>{el.driver}</b>
                  </td>
                  <td className="ant-table-cell">{el.createdBy}</td>
                  <td>
                    <Popconfirm
                      title={`Voulez vous vraiment supprimer cette livraison?`}
                      onConfirm={() =>
                        toRemoveDelivery(el.id).then((res) => {
                          getData(date);
                        })
                      }
                      okText="Oui"
                      cancelText="Non"
                    >
                      <Button style={{ marginRight: 10 }} icon={<DeleteOutlined />} />
                    </Popconfirm>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Row>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

export default connect(null, mapDispatchToProps)(Navigation);
